import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/models/app-state.model';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.scss']
})
export class FooterTwoComponent implements OnInit {

  constructor(private store: Store<AppState>) { }
  country
  ngOnInit(): void {

    this.store.select((store) => store.Country).subscribe((country: any) => {

      this.country = country
    })
  }

}
