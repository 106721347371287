<section class="poster-section">
  <div class="title-row">
    <!-- <img src="assets/header-two/NI-06.png" alt="" /> -->
    <div>
      <h3>لماذا برق؟</h3>
    </div>
    <!-- <img src="assets/header-two/NI-06.png" alt="" /> -->
  </div>

  <div class="blog-slider below-desktops">
    <div class="blog-slider__wrp swiper-wrapper">
      <!-- step-3 -->

      <div class="blog-slider__item swiper-slide">
        <div class="blog-slider__img">
          <img src="../../../../../assets/why-barq.png" alt="" />
        </div>
        <div class="blog-slider__content">
          <!-- <span class="blog-slider__code">26 December 2019</span> -->
          <div class="blog-slider__title">
            لتعليم أطفالنا عن طريق اللعب و الاستكشاف
          </div>
          <div class="blog-slider__text">
            صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير مهارات
            طفلك عن طريق اللعب التجريبي.
          </div>
          <!-- <a href="#" class="blog-slider__button">READ MORE</a> -->
        </div>
      </div>

      <!-- step-2 -->
      <div class="blog-slider__item swiper-slide">
        <div class="blog-slider__img">
          <img src="../../../../../assets/why-barq.png" alt="" />
        </div>
        <div class="blog-slider__content">
          <!-- <span class="blog-slider__code">26 December 2019</span> -->
          <div class="blog-slider__title-2">
            يساعدك برق على قضاء وقت ممتع مع أطفالك
          </div>
          <div class="blog-slider__text">
            لتحفيز الإبداع الطبيعي للأطفال وفضولهم، مع مساعدة الاهل الذين يرغبون
            في تقديم تجارب ثرية لأطفالهم
          </div>
          <!-- <a href="#" class="blog-slider__button">READ MORE</a> -->
        </div>
      </div>

      <!-- step-1 -->
      <div class="blog-slider__item swiper-slide">
        <div class="blog-slider__img">
          <img src="../../../../../assets/why-barq.png" alt="" />
        </div>
        <div class="blog-slider__content">
          <!-- <span class="blog-slider__code">26 December 2019</span> -->
          <div class="blog-slider__title-3">
            لإلهام الجيل القادم من المبتكرين
          </div>
          <div class="blog-slider__text">
            يحتاج العالم العربي إلى مزيد من الابتكار وحل المشكلات. لذلك قررنا أن
            نبدأ على نطاق صغير - تعليم الأطفال الابتكار وحل المشكلات
          </div>
          <!-- <a href="#" class="blog-slider__button">READ MORE</a> -->
        </div>
      </div>
    </div>
    <div class="blog-slider__pagination"></div>
  </div>

  <div class="blog-slider-2 below-tablets">
    <div class="blog-slider-2__wrp swiper-wrapper">
      <!-- step-3 -->

      <div class="blog-slider-2__item swiper-slide">
        <div class="blog-slider-2__content">
          <!-- <span class="blog-slider__code">26 December 2019</span> -->
          <div class="blog-slider-2__title">
            لتعليم أطفالنا عن طريق اللعب و الاستكشاف
          </div>
          <div class="blog-slider-2__img">
            <img src="../../../../../assets/why-barq.png" alt="" />
          </div>
          <div class="blog-slider-2__text">
            صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير مهارات
            طفلك عن طريق اللعب التجريبي.
          </div>
          <!-- <a href="#" class="blog-slider__button">READ MORE</a> -->
        </div>
      </div>

      <!-- step-2 -->
      <div class="blog-slider-2__item swiper-slide">
        <div class="blog-slider-2__content">
          <!-- <span class="blog-slider__code">26 December 2019</span> -->
          <div class="blog-slider-2__title-2">
            يساعدك برق على قضاء وقت ممتع مع أطفالك
          </div>
          <div class="blog-slider-2__img">
            <img src="../../../../../assets/why-barq.png" alt="" />
          </div>
          <div class="blog-slider-2__text">
            لتحفيز الإبداع الطبيعي للأطفال وفضولهم، مع مساعدة الاهل الذين يرغبون
            في تقديم تجارب ثرية لأطفالهم
          </div>
          <!-- <a href="#" class="blog-slider__button">READ MORE</a> -->
        </div>
      </div>

      <!-- step-1 -->
      <div class="blog-slider-2__item swiper-slide">
        <div class="blog-slider-2__content">
          <!-- <span class="blog-slider__code">26 December 2019</span> -->
          <div class="blog-slider-2__title-3">
            لإلهام الجيل القادم من المبتكرين
          </div>
          <div class="blog-slider-2__img">
            <img src="../../../../../assets/why-barq.png" alt="" />
          </div>
          <div class="blog-slider-2__text">
            يحتاج العالم العربي إلى مزيد من الابتكار وحل المشكلات. لذلك قررنا أن
            نبدأ على نطاق صغير - تعليم الأطفال الابتكار وحل المشكلات
          </div>
          <!-- <a href="#" class="blog-slider__button">READ MORE</a> -->
        </div>
      </div>
    </div>
    <div class="blog-slider-2__pagination"></div>
  </div>
</section>
