import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CountriesService } from '../../services/api/countries.service';
import { Country } from 'src/app/models/models';
export type NAV_THEME = 'dark' | 'light';

@Injectable({
  providedIn: 'root',
})
export class TopNavService {
  countriesList: Country[] = [];
  subscriptionGroup: number;
  constructor(private countriesService: CountriesService) {
    this.countriesService.getCountries().subscribe((response) => {
      this.countriesList = response.data;
    });
  }

  country: number = JSON.parse(localStorage.getItem('country') as any)?.id ?? 1;
  country_exchange_rate: number = JSON.parse(
    localStorage.getItem('country') as any
  )?.exchange_rate;

  // country: number = (localStorage.getItem('country') as any)?.id ?? 1;
  menuSwitch = new Subject<boolean>();
  theme = new BehaviorSubject<NAV_THEME>('dark');

  private eventSubject = new BehaviorSubject<any>(this.country);

  triggerSomeEvent(param: any, form: any) {
    this.eventSubject.next({param, form});
  }

  getEventSubject(): BehaviorSubject<any> {
    return this.eventSubject;
  }
}
