import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

export type ID = string | number;

export type METHOD = 'POST' | 'UPDATE' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';

const injector = Injector.create({
    providers: [
        { provide: HttpClient, deps: [HttpHandler] },
        { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
    ],
});

class HttpRequestQueryBuilder extends RequestQueryBuilder {
    private URL = '';
    private http = injector.get<HttpClient>(HttpClient);
    private method = '';
    private headersObject: { [key: string]: string } = {};
    private bodyObject = {};
    private id: ID;
    private subRoute: Array<string | number> = null;
    constructor(method, url: string) {
        super();
        this.URL = url;
        this.method = method;
    }

    setHeaders(header: { [key: string]: string }): HttpRequestQueryBuilder {
        this.headersObject = header;
        return this;
    }

    setBody(body: any): HttpRequestQueryBuilder {
        this.bodyObject = body;
        return this;
    }



    execute<T = any>(): Observable<T> {
        const token: string = localStorage.getItem('token');
        if (token) {
            this.headersObject.Authorization = 'Bearer ' + token;
        }
        return this.http.request<T>(
            this.method,
            this.URL,
            {
                params: this.queryObject,
                headers: this.headersObject,
                body: this.bodyObject
            }
        );
    }
}

export class HttpNestBase {
    private url = '';
    private qb?: HttpRequestQueryBuilder;
    private route = '';
    private subRoute: Array<string | number> | string | number = null;

    constructor(url: string, route: string) {
        this.url = url;
        this.route = route;
    }

    request(method: METHOD): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute()]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder(method, route);
    }

    post(): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute()]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder('POST', route);
    }

    update(id: ID = null): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute(), id]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder('PUT', route);
    }

    delete(id: ID = null): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute(), id]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder('DELETE', route);
    }

    patch(id: ID = null): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute(), id]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder('PATCH', route);
    }

    get(id: ID = null): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute(), id]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder('GET', route);
    }

    find(): HttpRequestQueryBuilder {
        const route = this.getRoute([this.url, this.route, ...this.getSubRoute()]);
        this.subRoute = null;
        return this.qb = new HttpRequestQueryBuilder('GET', route);
    }

    setSubRoute(route: Array<string | number> | string | number): HttpNestBase {
        this.subRoute = route;
        return this;
    }

    private getRoute(arr: Array<any>): string {
        const route = arr.filter(i => i != null).join('/');
        return route;
    }

    private getSubRoute(): (string | number)[] {
        if (!this.subRoute) { return []; }
        else if (typeof this.subRoute === 'number') { return [this.subRoute]; }
        else if (Array.isArray(this.subRoute)) { return this.subRoute; }
        else { return this.subRoute.split('/'); }
    }

}
