import {
  Inject,
  NgModule,
  Optional,
  PLATFORM_ID,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  GoogleTagManagerModule,
  GoogleTagManagerService,
} from 'angular-google-tag-manager';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MetaLoader,
  MetaModule,
  MetaStaticLoader,
  PageTitlePositioning,
} from '@ngx-meta/core';
import { environment } from 'src/environments/environment';
import { MatNativeDateModule } from '@angular/material/core';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { GeneralService } from './shared/services/app/general.service';
import { isPlatformBrowser } from '@angular/common';
import * as userAgent from 'user-agent-parse';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: environment.title,
    defaults: {
      title: environment.title,
      description:
        'ألعاب و مشاريع صغيرة للمبتكرين الصغار, لتنمية حسهم الإبداعي و الترفيه عنهم',
      'og:title': environment.title,
      'og:image': 'https://barqboxes.com/assets/share-barq.png',
      'og:type': 'website',
      'og:locale': 'ar-JO',
      'og:locale:alternate': 'en_US',
      'og:url': 'https://barqboxes.com',
      'twitter:card': 'summary_large_image',
      'twitter:site': '@barq_boxes',
    },
  });
}

const imports = [
  ...[
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
    }),
    MatSnackBarModule,
    MatNativeDateModule,
    SocialLoginModule,
  ],
];

const providers: Array<any> = [
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(environment.FACEBOOK_ID),
        },
      ],
      onError: (err) => {
        console.error(err);
      },
    } as SocialAuthServiceConfig,
    CookieService,
  },
];

if (environment.ANALYTICS)
  imports.push(GoogleTagManagerModule.forRoot({ id: 'GTM-MWMRX6G' }));
else providers.push({ provide: GoogleTagManagerService, useValue: {} });
@NgModule({
  declarations: [AppComponent],
  imports,
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Optional() @Inject(REQUEST) protected request: Request,
    private generalService: GeneralService
  ) {
    // if (!isPlatformBrowser(this.platformId)) {
    //   this.generalService.setDeviceInfo(
    //     userAgent.parse(request.headers['user-agent'])
    //   );
    // } else {
    //   this.generalService.setDeviceInfo(userAgent.parse(navigator.userAgent));
    // }
  }
}
