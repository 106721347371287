import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { LoginBody, SignUpBody, User } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';
import { UsersService } from './users.service';
import { SocialAuthService } from 'angularx-social-login';

type LoginResponse = { access_token: string };
@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpNestBase {
  private _user = new BehaviorSubject<User>(null);
  helper = new JwtHelperService();
  isLogin = false;

  // const decodedToken = helper.decodeToken(myRawToken);
  // const expirationDate = helper.getTokenExpirationDate(myRawToken);
  // const isExpired = helper.isTokenExpired(myRawToken);

  constructor(
    private usersService: UsersService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private socialAuthService: SocialAuthService
  ) {
    super(environment.API_URL, 'auth');
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  get user(): User {
    return this._user.value ?? null;
  }

  signUp(body: SignUpBody): void {
    this.usersService
      .post()
      .setBody(body)
      .execute<any>()
      .pipe(
        map(() => ({ password: body.password, email: body.email })),
        switchMap((loginInfo) => this.login(loginInfo))
      )
      .subscribe(() => this.afterLoginRedirection());
  }

  login(body: LoginBody): Observable<{ access_token: string }> {
    return this.setSubRoute('login')
      .post()
      .setBody(body)
      .execute<{ access_token: string }>()
      .pipe(
        catchError((err) => {
          this.loginFailed(err);
          return throwError(err);
        }),
        filter((token) => !!token),
        tap((token) => this.loginSuccessfully(token))
      );
  }

  authWithFacebook(access_token: string) {
    return this.setSubRoute(`facebook?access_token=${access_token}`)
      .get()
      .execute<any>()
      .pipe(
        tap((d) => this.loginSuccessfully(d)),
        catchError((e) => {
          this.loginFailed(e);
          return throwError(e);
        })
      );
  }

  authWithGoogle(access_token: string) {
    return this.setSubRoute(`google?access_token=${access_token}`)
      .get()
      .execute<any>()
      .pipe(
        tap((d) => this.loginSuccessfully(d)),
        catchError((e) => {
          this.loginFailed(e);
          return throwError(e);
        })
      );
  }

  authWithApple(body: {
    access_token: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  }) {
    return this.setSubRoute(`apple?access_token=${body.access_token}`)
      .post()
      .setBody(body)
      .execute<any>()
      .pipe(
        tap((d) => this.loginSuccessfully(d)),
        catchError((e) => {
          this.loginFailed(e);
          return throwError(e);
        })
      );
  }

  loginSuccessfully(token: LoginResponse): void {
    const decodeToken = this.getDecodeToken(token.access_token);
    localStorage.setItem('token', token.access_token);
    this.isLogin = true;
    this._user.next(decodeToken);
  }

  loginFailed(error: any): void {
    console.error(error);
    this._user.next(null);
  }

  getDecodeToken(t: string = null): User | null {
    const token = t || localStorage.getItem('token');
    let decodeToken = null;
    if (token) {
      decodeToken = this.helper.decodeToken(token);
    }
    return decodeToken;
  }

  logout(): void {
    localStorage.removeItem('token');
    this.isLogin = false;
    this._user.next(null);
    this.afterLogoutRedirection();
    this.socialAuthService.signOut();
  }

  patchAuthStateThereAreAToken(): void {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const decodeToken = this.helper.decodeToken(token);
        const isExpired = this.helper.isTokenExpired(token);
        if (!isExpired) {
          // this.loginSuccessfully({ access_token: token });
          this.isLogin = true;
          this.getUser().toPromise();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  afterLoginRedirection(): void {
    const queryParams = this.activeRoute.snapshot.queryParams;
    if (this.user && this.user.role == 'admin')
      if (queryParams?.redirect) this.router.navigate([queryParams.redirect]);
      else window.location.href = '/admin/dashboard';
    else if (queryParams?.redirect)
      this.router.navigate([queryParams.redirect]);
    else this.router.navigate(['/account']);
  }

  afterLogoutRedirection(): void {
    this.router.navigate(['/']);
  }

  getUser(): Observable<User> {
    const { id } = this.getDecodeToken();
    if (id) {
      return this.usersService
        .get(id)
        .execute<User>()
        .pipe(tap((user) => this._user.next(user)));
    } else {
      return of(null);
    }
  }

  forgotPassword(body: { mail: string }): Observable<any> {
    return this.setSubRoute('forgot-password')
      .post()
      .setBody(body)
      .execute<any>()
      .pipe(catchError((e) => throwError(e)));
  }

  resetPassword(body, token: string): Observable<any> {
    return this.setSubRoute(['reset-password', token])
      .post()
      .setBody(body)
      .execute<any>()
      .pipe(catchError((e) => throwError(e)));
  }

  unsubscribeEmail(token: string): Observable<any> {
    return this.setSubRoute(['unsubscripe', token])
      .post()
      .execute<any>()
      .pipe(catchError((e) => throwError(e)));
  }

  verifying(token: string): Observable<any> {
    return this.setSubRoute(['verifying', token])
      .get()
      .execute<any>()
      .pipe(catchError((e) => throwError(e)));
  }

  isAdmin() {
    const token = localStorage.getItem('token');
    if (token) {
      if (this.helper.decodeToken(token).role == 'admin') return true;
      else {
        this.router.navigate(['/']);
        return false;
      }
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  isTokenValid() {
    const token = localStorage.getItem('token');
    if (token) {
      return this.helper.isTokenExpired(token);
    } else return true;
  }
}
