<section class="section-type-input">
  <h3
    class="form-section-title"
    [ngClass]="{
      'form-section-title-2': router.url === homeUrl.home
    }"
  >
    اختر الفئة العمرية :
  </h3>

  <div [class]="'proper-age-container ' + theme">
    <ng-container *ngIf="list$ | async as list">
      <div
        [id]="'card-age-card' + sub.id"
        class="list"
        *ngFor="let sub of list"
        matRipple
        [class.selected]="id == sub.id"
        (click)="selected(sub)"
      >
        <div class="box-img">
          <app-image
            [id]="'card-age-img' + sub.id"
            [images]="[
              {
                alt: '',
                src: environment.API_URL + '/media/file/' + sub.image_id
              }
            ]"
          ></app-image>

          <span [id]="'card-age-title' + sub.id">{{ sub?.title }}</span>
        </div>

        <div class="box-img-2">
          <span [id]="'card-age-range' + sub.id">{{ sub | ageRange }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!list$">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</section>
