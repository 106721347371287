import { Action } from '@ngrx/store';
import { Country } from '../models/country.model';

export enum CountryActionType {
  ADD_ITEM = '[Country] Add Country',
}

export class SetCountryAction implements Action {
  readonly type = CountryActionType.ADD_ITEM;

  //add an optional payload

  constructor(public payload: Country) {
   }
}

export type CountryAction = SetCountryAction;
