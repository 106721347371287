<section class="header-section">
  <div class="header-container">
    <header>
      <div>
        <h3>لكي نلهم أطفالنا!</h3>
        <h4 class="sub-title-1">
          مشاريع علمية حقـــــاً وممتعة جــــــــداً للمبتكرين الصغار لتنمية
          حسهم الإبداعي و الترفيه عنهم...
        </h4>

        <h4 class="sub-title-2">
          مشاريع علمية حقـــــاً وممتعة جــــــــداً للمبتكرين الصغار
        </h4>
        <button
          class="submit-button sub-header"
          mat-button
          [routerLink]="['/how-to-subscription']"
        >
          اشترك الآن
        </button>
      </div>

      <div style="position: relative">
        <!-- <img [src]="'assets/children.png' | imageExt" alt="children" /> -->
        <app-image
          [lazy]="false"
          [images]="[
            {
              maxWidth: 600,
              width: 600,
              height: 442,
              alt: 'children',
              src: 'assets/header-two/Pasted-image.png'
            },
            {
              maxWidth: 1200,
              width: 600,
              height: 442,
              alt: 'children',
              src: 'assets/header-two/Pasted-image.png'
            }
          ]"
        ></app-image>
      </div>
    </header>
  </div>
</section>
