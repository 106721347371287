import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  APIResponse,
  Note,
  User,
  UserSubscription,
} from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';

interface AddNoteToUserSubscription extends Partial<UserSubscription> {
  notes: Array<Note>;
  user: User;
}

export enum SUBSCRIPTION_STATUS {
  CREATED = 'CREATED',
  ACTIVATED = 'ACTIVATED',
  SUSPENDED = 'SUSPENDED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  UPDATED = 'UPDATED',
  'PAYMENT.FAILED' = 'PAYMENT.FAILED',
}

@Injectable({
  providedIn: 'root',
})
export class UserSubscriptionsService extends HttpNestBase {
  constructor() {
    super(environment.API_URL, 'user-subscription');
  }
  getUserSubscriptions(userId): Observable<APIResponse<UserSubscription>> {
    return this.find()
      .setJoin({ field: 'orders' })
      .setJoin({ field: 'orders.product' })
      .setFilter([
        {
          field: 'user.id',
          operator: '$eq',
          value: userId,
        },
      ])
      .setLimit(1000)
      .execute<APIResponse<UserSubscription>>();
  }

  getUserSubscriptionsProducts(
    id: number
  ): Observable<APIResponse<UserSubscription>> {
    return this.get(id)
      .setJoin({ field: 'orders' })
      .setJoin({ field: 'orders.product' })
      .setJoin({ field: 'orders.product.tag_image' })
      .setJoin({ field: 'orders.product.main_image' })
      .setLimit(1000)
      .execute<APIResponse<UserSubscription>>();
  }

  getOrderSubscriptionStatus(id): Observable<string> {
    return this.get(id)
      .execute()
      .pipe(map((d) => d.status));
  }

  deleteUserSubscription(id: number): Observable<null> {
    return this.delete(id).execute();
  }

  addSubscription(body: UserSubscription): Observable<UserSubscription> {
    return this.post().setBody(body).execute<UserSubscription>();
  }

  updateSubscription(
    id: number,
    body: UserSubscription
  ): Observable<UserSubscription> {
    return this.update(id).setBody(body).execute();
  }

  patchSubscription(
    id: number,
    body: Partial<UserSubscription>
  ): Observable<UserSubscription> {
    return this.patch(id).setBody(body).execute<UserSubscription>();
  }

  getSubscription(id: number): Observable<UserSubscription> {
    return this.get(id).execute<UserSubscription>();
  }

  getFullSubscriptions(page, limit): Observable<APIResponse<UserSubscription>> {
    return this.find()
      .setPage(page)
      .setLimit(limit)
      .setJoin({ field: 'child' })
      .setJoin({ field: 'user' })
      .setJoin({ field: 'subscription' })
      .setJoin({ field: 'subscription.subscription_type' })

      .setFilter({ field: 'payment_method', operator: '$notnull', value: null })
      .execute<APIResponse<UserSubscription>>();
  }

  getFullSubscription(id: number): Observable<UserSubscription> {
    return this.get(id)
      .setJoin({ field: 'user' })
      .setJoin({ field: 'child' })
      .setJoin({ field: 'notes' })
      .setJoin({ field: 'orders_items' })
      .setJoin({ field: 'orders_items.order' })
      .setJoin({ field: 'orders_items.product' })
      .setJoin({ field: 'subscription' })
      .setJoin({ field: 'subscription.subscription_type' })
      .setJoin({ field: 'subscription_group' })
      .setJoin({ field: 'subscription_group.address' })
      .execute<UserSubscription>();
  }

  getLastUncompletedSubscription(userId: number): Observable<UserSubscription> {
    return this.find()
      .setJoin({ field: 'user' })
      .setJoin({ field: 'subscription_group' })
      .setFilter([
        {
          field: 'user.id',
          operator: '$eq',
          value: userId,
        },
      ])
      .setLimit(1)
      .sortBy({ field: 'id', order: 'DESC' })
      .execute()
      .pipe(map((r) => r?.data[0]));
  }

  private serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  getSubscriptionsByIds(
    ids: Array<number>
  ): Observable<APIResponse<UserSubscription>> {
    return this.find()
      .setFilter({ field: 'id', operator: '$in', value: ids })
      .setLimit(100)
      .execute<APIResponse<UserSubscription>>();
  }

  getSubscriptionGroupId(
    id: number,
    option: { withDeletion?: boolean } = { withDeletion: false }
  ): Observable<UserSubscription[]> {
    const filter = [];
    filter.push({ field: 'subscription_group_id', operator: '$eq', value: id });
    if (typeof option.withDeletion == 'boolean')
      filter.push({
        field: 'deleted',
        operator: '$eq',
        value: option.withDeletion,
      });
    return this.find()
      .setFilter(filter)
      .select(['user_subscriptions'])
      .setLimit(100)
      .execute<APIResponse<UserSubscription>>()
      .pipe(map((SG) => SG.data));
  }

  addNote(id: number, body: AddNoteToUserSubscription): Observable<any> {
    return this.patch(id).setBody(body).execute();
  }
}
