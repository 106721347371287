<ng-container *ngIf="lazy; else elseTemplate">
  <!-- <img [lazyLoad]="_image" [alt]="alt" [height]="height" [width]="width" /> -->
  <picture>
    <source
      *ngFor="let image of _images"
      [media]="'(max-width:' + image.maxWidth + 'px)'"
      [attr.lazyLoad]="image.src"
    />
    <img [lazyLoad]="_images[_images.length - 1]?.src" />
  </picture>
</ng-container>
<ng-template #elseTemplate>
  <!-- <img [src]="_image" [alt]="alt" [height]="height" [width]="width" /> -->
  <picture>
    <source
      *ngFor="let image of _images"
      [media]="'(max-width:' + image.maxWidth + 'px)'"
      [attr.lazyLoad]="image.src"
    />
    <img [src]="_images[_images.length - 1]?.src" />
  </picture>
</ng-template>
