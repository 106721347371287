import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.scss'],
})
export class ProductsSectionComponent implements OnInit {
  [x: string]: any;
  loop: any;
  autoplay: any;
  video: any;
  realIndex: any;
  // slides: any;
  // currentSlide: any;
  @ViewChild('vid') vid;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    public router: Router
  ) {}

  currentSlide = 0;

  slider = [
    {
      image: '../../../../../assets/gif/vid-1.webm',
    },
    {
      image: '../../../../../assets/gif/vid-3.webm',
    },
    {
      image: '../../../../../assets/gif/vid-2.webm',
    },
  ];

  slides = [
    {
      image: '../../../../../assets/gif/Flight-Platform-co.webm',
    },
    {
      image: '../../../../../assets/gif/Geo-Drawing-co.webm',
    },
    {
      image: '../../../../../assets/gif/Globe-co.webm',
    },
    {
      image: '../../../../../assets/gif/robot-co.webm',
    },
  ];

  async ngOnInit(): Promise<void> {
    // this.injectScript('/assets/js/script.js');
    // this.onSlideChange();
    // if (isPlatformBrowser(this.platformId)) {
    //   const videos = document.getElementsByTagName('video') as any;
    //   for (const video of videos) {
    //     video.muted = true;
    //     // console.log(video);
    //     // if (videos[0]) {
    //     //   video.muted = true;
    //     //   video.play();
    //     //   console.log(video);
    //     // } else {
    //     //   video.pause();
    //     // }
    //   }
    // }
    // // this.autoplays();
  }

  // public injectScript(src: string) {
  //   if (this.document && src?.trim()) {
  //     const script = this.document.createElement('script');
  //     script.setAttribute('type', 'text/javascript');
  //     script.setAttribute('src', src.trim());
  //     this.document.head?.appendChild(script);
  //   }
  // }

  ngAfterViewInit() {
    //script

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/js/script.js';

    s.defer = true;
    this.elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };

    if ((typeof window as any['functionFromExternalScript']) === 'function') {
      window as any['functionFromExternalScript'];
      params;
    }
  }

  public aboutUrl = {
    about: '/about-us',
  };

  public homeUrl = {
    home: '/home',
  };
}
