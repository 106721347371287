<div [class]="'bg ' + theme" name="heder-height">
  <ng-container *ngIf="showToolBar?.visibility">
    <mat-toolbar class="toolbar">
      <span>{{ showToolBar?.message }}</span>
    </mat-toolbar>
  </ng-container>
  <nav>
    <!-- <button
      [class]="'menu-icon ' + theme"
      mat-icon-button
      (click)="menuSwitch()"
    >
      <mat-icon>menu</mat-icon>
    </button> -->
    <div class="mobile">
      <div class="basket-mobile">
        <div class="right-nav">
          <button
            [class]="'menu-icon ' + theme"
            mat-icon-button
            (click)="menuSwitch()"
          >
            <span class="new">*</span>
            <mat-icon>menu</mat-icon>
          </button>
          <button (click)="openBasket()">
            <img src="assets/images/cart-icon.svg" alt="basket" />
            <span
              class="count"
              *ngIf="this.basketService.totalInLocalCurrency > 0"
              >{{ basketService.basketSize }}</span
            >
          </button>
        </div>
        <div class="left-nav">
          <a
            *ngIf="country"
            class="country"
            mat-button
            [matMenuTriggerFor]="flags"
            name="account-button"
          >
            {{ country.name_ar }}
            <img
              width="30"
              [src]="
                country.code == 'US'
                  ? 'assets/flags/' + country.code + '.png'
                  : 'assets/flags/' + country.code + '.svg'
              "
              [alt]="country.name_ar"
            />
          </a>
          <a [routerLink]="'/'" name="home-button">
            <img
              class="logo"
              width="122"
              height="56"
              [src]="
                theme == 'light'
                  ? 'assets/yellowlogo.png'
                  : 'assets/logo-white.svg'
              "
              alt=""
            />
          </a>
        </div>
      </div>
    </div>

    <a [routerLink]="'/'" name="home-button">
      <img
        class="logo desktop"
        width="122"
        height="56"
        [src]="
          theme == 'light' ? 'assets/yellowlogo.png' : 'assets/logo-white.svg'
        "
        alt=""
      />
    </a>
    <!-- <button class="menu-icon" mat-icon-button (click)="menuSwitch()"><mat-icon>notifications</mat-icon></button> -->
    <div [class]="'routes ' + theme">
      <a mat-button [routerLink]="['/about-us']" name="about-us-button"
        >من نحن</a
      >
      <mat-divider [vertical]="true"></mat-divider>
      <a mat-button [routerLink]="['/shop']" class="shop" name="about-us-button"
        >المتجر
        <span class="new">*</span>
      </a>
      <mat-divider [vertical]="true"></mat-divider>
      <a
        mat-button
        [routerLink]="
          topNavService.subscriptionGroup
            ? ['/', 'order', topNavService.subscriptionGroup]
            : ['/how-to-subscription']
        "
        name="how-to-subscripe-button"
        >كيف تشترك</a
      >
      <mat-divider [vertical]="true"></mat-divider>
      <a
        mat-button
        [routerLink]="['auth', 'signin']"
        *ngIf="!authService.isLogin"
        name="login-button"
        >تسجيل دخول</a
      >
      <a
        mat-button
        [matMenuTriggerFor]="menu"
        *ngIf="authService.isLogin"
        name="account-button"
        >حسابي <mat-icon> account_circle</mat-icon></a
      >

      <a *ngIf="country" mat-button [matMenuTriggerFor]="flags">
        {{ country.name_ar }}
        <img
          width="30"
          [src]="
            country.code == 'US'
              ? 'assets/flags/' + country.code + '.png'
              : 'assets/flags/' + country.code + '.svg'
          "
          [alt]="country.name_ar"
        />
      </a>
    </div>
    <div class="basket">
      <button (click)="openBasket()">
        <img src="assets/images/cart-icon.svg" alt="basket" />
        <span
          class="count"
          *ngIf="this.basketService.totalInLocalCurrency > 0"
          >{{ basketService.basketSize }}</span
        >
      </button>
    </div>
  </nav>
</div>
<mat-menu #flags="matMenu">
  <button
    class="flagmenu"
    *ngFor="let country of countries"
    (click)="currencySwitch(country)"
    mat-menu-item
    [name]="country.name_ar"
  >
    <span>{{ country.name_ar }}</span>
    <img
      width="30"
      [src]="
        country.code == 'US'
          ? 'assets/flags/' + country.code + '.png'
          : 'assets/flags/' + country.code + '.svg'
      "
      [alt]="country.name_ar"
    />
  </button>
</mat-menu>
<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/account']" name="my-account">
    حسابي
  </button>
  <!-- <button mat-menu-item>مشترياتي</button> -->
  <button mat-menu-item (click)="authService.logout()" name="logout">
    تسجيل الخروج
  </button>
</mat-menu>
