<section class="get-started-section">
  <div class="title-row">
    <!-- <img src="assets/header-two/NI-06.png" alt="" /> -->

    <div>
      <h3>اشّهر من المتعة و التعلم</h3>
    </div>
    <img src="assets/header-two/heart-icon.png" alt="" />
  </div>
  <p class="choose-plan-text">اختر خطة اشتراك واستعد لذكريات لن تنسى,</p>
  <!-- <p>او عن طريق <a class="white" [routerLink]="['/shop']">متجر برق </a></p> -->

  <div class="get-started">
    <section>
      <div>
        <h2 class="what-sub">ماذا يتضمن الاشتراك</h2>

        <div class="get-started-box">
          <div class="get-started-flex-box">
            <p class="get-text">مشروع مناسب لعمر طفلك من اختيارنا</p>
            <img src="../../../../../assets/barq-card/icon-4.svg" alt="" />
          </div>

          <div class="get-started-flex-box">
            <p class="get-text">مجلة علمية</p>
            <img src="../../../../../assets/barq-card/icon-3.svg" alt="" />
          </div>

          <div class="get-started-flex-box">
            <p class="get-text">دليل تركيب</p>
            <img src="../../../../../assets/barq-card/icon-2.svg" alt="" />
          </div>

          <div class="get-started-flex-box">
            <p class="get-text">صندوق مخصص باسم طفلك</p>
            <img src="../../../../../assets/barq-card/icon-1.svg" alt="" />
          </div>
        </div>

        <!-- <app-subscription-input
        (changed)="subscriptionSelected($event)"
      ></app-subscription-input> -->

        <!-- <div class="s-32">
          <app-subscription-input
            (changed)="subscriptionSelected($event)"
          ></app-subscription-input>
        </div> -->

        <app-subscription-type-input
          *ngIf="!subsData.subscription_type_id"
          (changed)="subscriptionSelected($event)"
        ></app-subscription-type-input>

        <app-subscription-input
          *ngIf="subsData.subscription_type_id"
          (changed)="subscriptionSelecteds($event)"
        ></app-subscription-input>

        <!-- <section class="s-33">
          <div class="swiper-2">
            <div class="swiper-wrapper">
            
              <div
                class="swiper-slide swiper-slide--one"
                data-hash="slide-1"
                *ngFor="let sub of subsData"
              >
                <ng-container>
                  <app-subscription-input
                    (changed)="subscriptionSelected($event)"
                  ></app-subscription-input>
                </ng-container>
              </div>
            </div>
         
            <div class="swiper-pagination-2"></div>
          </div>
        </section> -->
        <a
          class="subscribe-now-button"
          mat-button
          [routerLink]="
            topNavService.subscriptionGroup
              ? ['/', 'order', topNavService.subscriptionGroup]
              : ['/how-to-subscription']
          "
          >إشترك الآن</a
        >

        <p class="subtitle">
          * يتم تجديد الاشتراكات تلقائيا. التوفير مقارنة بالخطة الشهرية.
        </p>
      </div>
    </section>
  </div>
</section>
