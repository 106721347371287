   <div class="form-container" >
    <div class="form-title">
      <span></span>
      <h3>تأكيد الغاء الاشتراك</h3>
    </div>
    <span class="message">   
       * لألغاء استقبال الرسائل اضغط 
        
       <span class="confirm"> تأكيد    
       </span>
    </span>

    <div class="buttons-container">
        <a  type="button" [href]="domain">رجوع</a>
        <button mat-flat-button color="warn" type="button" (click)="confirm()">تأكيد</button>
    </div>
  </div>