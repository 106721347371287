import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';

@Injectable({
  providedIn: 'root',
})
export class ConversionService extends HttpNestBase {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    super(environment.API_URL, 'conversion-api');
  }
  fbclid;
  country;
  _fbp;
  viewPage() {
    if (this.isAllowToSend())
      this.setSubRoute('viewPage')
        .post()
        .setBody({
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }

  viewContent() {
    if (this.isAllowToSend())
      this.setSubRoute('viewContent')
        .post()
        .setBody({
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }

  lead(id) {
    if (this.isAllowToSend())
      this.setSubRoute('lead/' + id)
        .post()
        .setBody({
          id,
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }

  addToCart(id: number) {
    if (this.isAllowToSend())
      this.setSubRoute('addToCart/' + id)
        .post()
        .setBody({
          id,
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }

  shopAddToCart(product, country_id) {
    if (this.isAllowToSend())
      this.setSubRoute('shop-addToCart/')
        .post()
        .setBody({
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
          product,
          country_id,
        })
        .execute()
        .toPromise();
  }

  shopInitiateCheckout(id: number, currency = null, amount = null) {
    if (this.isAllowToSend())
      this.setSubRoute('shop-initiateCheckout/' + id)
        .post()
        .setBody({
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
          currency,
          amount,
        })
        .execute()
        .toPromise();
  }

  shopPurchase(order: any) {
    if (this.isAllowToSend())
      this.setSubRoute('shop-purchase/')
        .post()
        .setBody({
          order,
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }
  initiateCheckout(id: number) {
    if (this.isAllowToSend())
      this.setSubRoute('initiateCheckout/' + id)
        .post()
        .setBody({
          id,
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }

  purchase(id: number) {
    if (this.isAllowToSend())
      this.setSubRoute('purchase/' + id)
        .post()
        .setBody({
          id,
          fbclid: this._fbp ? this._fbp : this.fbclid ? this.fbclid : null,
          country: this.country ? this.country.code : null,
        })
        .execute()
        .toPromise();
  }

  isAllowToSend() {
    if (isPlatformBrowser(this.platformId) && environment.ANALYTICS) {
      this.fbclid = localStorage.getItem('fbclid');
      this.country = JSON.parse(localStorage.getItem('country'));
      this._fbp = this.getCookie('_fbp');
      return true;
    } else return false;
  }

  getCookie(name) {
    if (isPlatformBrowser(this.platformId)) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  }
}
