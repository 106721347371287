import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';
import { AuthService } from 'src/app/shared/services/api/auth.service';
import { SnackBarService } from 'src/app/shared/services/app/snack-bar.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-confirm-unsubscribe',
  templateUrl: './confirm-unsubscribe.component.html',
  styleUrls: ['./confirm-unsubscribe.component.scss']
})
export class ConfirmUnsubscribeComponent implements OnInit {
  domain:string = environment.DOMAIN;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private topNavService: TopNavService,
    ) { 
      this.topNavService.theme.next('light');
    }
  token: string;
  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
  }


  confirm() {
    this.authService.unsubscribeEmail(this.token).subscribe(res => 
      this.snackBarService.success(res.message)
    )
  }
}
