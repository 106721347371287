import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  Subscription,
  SubscriptionType,
  UserSubscription,
} from 'src/app/models/models';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';
import { SubscriptionsService } from 'src/app/shared/services/api/subscriptions.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Input() form: FormGroup;
  @Input() subscription: Subscription[];
  // sendTypeId = new BehaviorSubject<object>({});

  subscriptions: any = [];
  subsData: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private subscriptionsService: SubscriptionsService,
    private router: Router,
    public topNavService: TopNavService,
    private elementRef: ElementRef
  ) {}

  subscriptionSelected(user_subscription: UserSubscription): void {
    this.subscriptionsService.setTypeId({
      subscription_type_id: user_subscription?.id,
    });

    console.log((this.subsData = this.subscriptionsService.sendTypeId.value));

    // this.router.navigate(['/how-to-subscription'], {
    //   queryParams: { subscription_type_id: user_subscription?.id },
    // });
  }

  subscriptionSelecteds(subscription: Subscription): void {
    this.router.navigate(['/how-to-subscription'], {
      queryParams: { subscription_id: subscription?.id },
    });
    console.log(subscription);
  }

  async ngOnInit() {
    // this.subscriptions = await this.subscriptionsService
    //   .getActiveSubscriptions()
    //   .toPromise();
    // console.log(this.subscriptions);
    // this.subsData = this.subscriptions.data;
    // console.log(this.subsData);
    this.injectScript('/assets/js/script.js');
  }

  // subscriptionSelected(subscription_type: SubscriptionType): void {
  //   if (this.topNavService.subscriptionGroup) {
  //     this.router.navigate([
  //       '/',
  //       'order',
  //       this.topNavService.subscriptionGroup,
  //     ]);
  //   } else {
  //     this.router.navigate(['/how-to-subscription'], {
  //       queryParams: { subscription_type_id: subscription_type?.id },
  //     });
  //   }
  // }

  // subscriptionSelected(subscription_type: SubscriptionType): void {
  //   if(this.topNavService.subscriptionGroup) {
  //     this.router.navigate(['/', 'order', this.topNavService.subscriptionGroup]);
  //   } else {
  //     this.router.navigate(['/how-to-subscription'], {
  //       queryParams: { subscription_type_id: subscription_type?.id },
  //     });
  //   }

  public injectScript(src: string) {
    if (this.document && src?.trim()) {
      const script = this.document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', src.trim());
      this.document.head?.appendChild(script);
    }
  }

  // ngAfterViewInit() {
  //   var s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '/assets/js/script.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  // afterScriptAdded() {
  //   const params = {
  //     width: '350px',
  //     height: '420px',
  //   };

  //   if ((typeof window as any['functionFromExternalScript']) === 'function') {
  //     window as any['functionFromExternalScript'];
  //     params;
  //   }
  // }
}
