import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/models/models';

@Pipe({
  name: 'currency',
})
export class CurrencyPipe implements PipeTransform {
  transform(
    product: Product,
    countryId: number,
    discount: number,
    total: number,
    countries: any
  ): any {
    if (countries) {
      for (let country of countries ?? []) {
        //Count Total
        if (total && discount == undefined && countryId == country.id) {
          return total + country.currency_ar;
        }

        //if basket.length = 0
        if (total == 0 && countryId == country.id && discount == undefined) {
          return 0 + country.currency_ar;
        }
        let prod_price = product.usd_price;
        product.productsCountries?.filter((d) =>
          d.country_id == countryId ? (prod_price = d.price) : product.usd_price
        );
        //new price for product page
        if (countryId == country.id && country.id != 1) {
          if (discount && total == undefined) {
            let priceWithoutDiscount = 0;
            let discPrice = 0;
            let newPrice;
            discPrice = prod_price * (discount / 100);
            priceWithoutDiscount = prod_price - discPrice;
            newPrice = priceWithoutDiscount * country.exchange_rate;

            return (
              (
                Math.trunc(newPrice * Math.pow(10, 1)) / Math.pow(10, 1)
              ).toFixed(2) + country.currency_ar
            );
          } else {
            return (
              (prod_price * country.exchange_rate).toFixed(2) +
              country.currency_ar
            );
          }
        }

        //new price for product page
        if (countryId == 1 && country.id == 1) {
          if (discount && total == undefined) {
            let discPrice = 0;
            let newPrice;
            discPrice = product.jod_price * (discount / 100);
            newPrice = product.jod_price - discPrice;
            return Math.round(newPrice * 100) / 100 + country.currency_ar;
          } else {
            return product.jod_price + country.currency_ar;
          }
        }
      }
    }
  }
}
