import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  constructor(private http: HttpClient) { 
  }

  token: string = localStorage.getItem('token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.token}`
  });
  requestOptions = { headers: this.headers };



  getToolBarMsg(): Observable<any> {
    const url = `${environment.API_URL}/variables/toolbar`;
    return this.http.get(url, {headers: this.headers });
  }
}
