import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poster-three',
  templateUrl: './poster-three.component.html',
  styleUrls: ['./poster-three.component.scss'],
})
export class PosterThreeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
