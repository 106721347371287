import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Product } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}
  api = environment.API_URL;

  async getProduct(id: number): Promise<Product> {
    return await this.http
      .get(`${this.api}/products/public/${id}`)
      .pipe(map((d: any) => d))
      .toPromise();
  }
  getProducts(ids: number[] = []) {
    let queryString = `${this.api}/products/public`;
    if (ids.length > 0) queryString += `?ids=${ids.join(',')}`;
    return this.http.get<Product[]>(queryString);
  }
}
