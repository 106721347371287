import { Injectable } from '@angular/core';
import { HttpNestBase } from './request.class';
import { environment } from 'src/environments/environment';
import { Promotion } from 'src/app/models/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService extends HttpNestBase {
  constructor() {
    super(environment.API_URL, 'promotions');
  }

  getByCode(code: string): Observable<{
    promotion: Promotion;
    message: string;
    status_code: number;
  }> {
    if (!code) return of(null);
    return this.setSubRoute(`code/${code}`).get().execute();
  }

  upsertPromotionCode(code: string) {
    localStorage.setItem('p', code);
  }

  upsertTruckingCode(code: number) {
    if (code > 0) localStorage.setItem('t', `${code}`);
  }

  getPromotionCode() {
    return localStorage.getItem('p') ?? null;
  }

  getTruckingCode() {
    const code = +localStorage.getItem('t');
    return code > 0 ? code : null;
  }
}
