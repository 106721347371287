import { Injectable } from '@angular/core';
import { Country, Product, Promotion } from 'src/app/models/models';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';
import { ProductsService } from './product.service';
import { map, switchMap } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import * as objectPath from 'object-path';
import { PromotionsService } from 'src/app/shared/services/api/promotions.service';
import { CountriesService } from 'src/app/shared/services/api/countries.service';
export type CalculatedPrice = {
  prevuesPrice: number;
  currentPrice: number;
  fixedTotal: number;
  formattedCurrentPrice: string;
  formattedPrevuesPrice: string;
  promotion: Promotion;
  promotionType: string;
  discount: number;
  priceInUSD: number;
};

export type CalculatePriceParams = {
  price: number;
  deliveryPrice: number;
  discount?: number;
  promotion?: Promotion | string;
  promotionType?: 'STORE' | 'SUBSCRIPTION';
  country?: Country;
};

export type BasketItem = {
  product: Product;
  quantity: number;
  price: number;
  priceAfterDiscount: number;
  formattedCurrentPrice: string;
  formattedPrevuesPrice: string;
  isThereChange: boolean;
  priceInUSD: number;
};

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  private readonly storageKey = 'basket_map';
  public basket: BasketItem[] = [];
  public totalInLocalCurrency: number = 0;
  public totalInLocalUSD: number = 0;
  public currency: 'USD' | 'JOD' = 'JOD';
  state: 'loading' | 'empty' | 'data' = 'loading';
  get basketSize() {
    return this.basket.reduce((total, basketItem) => {
      total += basketItem.quantity;
      return total;
    }, 0);
  }

  constructor(
    private topNavService: TopNavService,
    private productService: ProductsService,
    private countriesService: CountriesService,
    private promotionsService: PromotionsService
  ) {
    this.currency = this.topNavService.country == 1 ? 'JOD' : 'USD';
    this.state = 'loading';
    this.loadBasket().subscribe((basket) => {
      this.state = basket.length == 0 ? 'empty' : 'data';
      this.basket = basket;
      this.afterChange();
    });
  }

  getBasketForOrder() {
    return this.loadBasket().pipe(
      map((items) => {
        return items.reduce((products, basketItem) => {
          basketItem.product;
          products.push({
            ...basketItem.product,
            quantity: basketItem.quantity,
          });
          return products;
        }, []);
      })
    );
  }

  private loadBasket() {
    let basketMap: {
      productId: number;
      quantity: number;
    }[] = [];

    const storedBasket = localStorage.getItem(this.storageKey);

    if (storedBasket !== null) {
      basketMap = JSON.parse(storedBasket);

      if (basketMap.length == 0) return of([]);

      return this.productService
        .getProducts(basketMap.map((item) => item.productId))
        .pipe(
          pipe(
            switchMap((products) => {
              return this.countriesService
                .getSelectedCountry()
                .pipe(map((country) => ({ country, products })));
            })
          ),
          map(({ country, products }) => {
            const basket = [];

            products.forEach((product) => {
              const quantity = basketMap.find(
                (item) => item.productId == product.id
              ).quantity;

              const basketItem = {
                quantity,
                product,
                price: this.getProductPrice(product, country),
                priceAfterDiscount: this.calculateProductPriceWithDiscount(
                  product,
                  country
                ),
                formattedCurrentPrice: '',
                formattedPrevuesPrice: '',
                isThereChange: false,
              };

              basketItem.formattedCurrentPrice = `${basketItem.priceAfterDiscount} ${country?.currency_ar}`;
              basketItem.formattedPrevuesPrice = `${basketItem.price} ${country?.currency_ar}`;
              basketItem.isThereChange =
                basketItem.price !== basketItem.priceAfterDiscount;

              basket.push(basketItem);
            });
            return basket;
          })
        );
    } else {
      return of([]);
    }
  }

  getSelectedCounty() {
    return this.countriesService.getSelectedCountry().toPromise();
  }

  async privateCalculateBasketItem(product: Product) {
    const country = await this.getSelectedCounty();
    return {
      product,
      quantity: 1,
      price: this.getProductPrice(product, country),

      priceAfterDiscount: this.calculateProductPriceWithDiscount(
        product,
        country
      ),
    };
  }

  async addItem(product: Product) {
    const basketItemRef = this.basket.find(
      (bi) => bi.product.id === product.id
    );
    if (!!basketItemRef) {
      basketItemRef.quantity++;
      return;
    }
    const country = await this.getSelectedCounty();
    const basketItem: BasketItem = {
      product,
      quantity: 1,
      price: this.getProductPrice(product, country),
      priceAfterDiscount: this.calculateProductPriceWithDiscount(
        product,
        country
      ),
      priceInUSD: this.calculateProductPriceWithDiscount(
        product,
        country,
        true
      ),
      formattedCurrentPrice: '',
      formattedPrevuesPrice: '',
      isThereChange: false,
    };
    basketItem.formattedCurrentPrice = `${basketItem.priceAfterDiscount} ${country?.currency_ar}`;
    basketItem.formattedPrevuesPrice = `${basketItem.price} ${country?.currency_ar}`;
    basketItem.isThereChange =
      basketItem.price !== basketItem.priceAfterDiscount;
    this.basket.push(basketItem);
    this.afterChange();
  }

  deleteItem(product: Product) {
    this.basket = this.basket.filter((basketItem) => {
      return basketItem.product.id !== product.id;
    });
    this.afterChange();
  }

  clearBasket() {
    this.basket = [];
    this.afterChange();
  }

  private saveBasket(): void {
    const basketMsp = this.basket.reduce((acc, basketItem) => {
      acc.push({
        productId: basketItem.product.id,
        quantity: basketItem.quantity,
      });
      return acc;
    }, []);
    if (basketMsp.length > 0) this.state = 'data';
    localStorage.setItem(this.storageKey, JSON.stringify(basketMsp));
  }

  decrementItem(product: Product) {
    const productRef = this.basket.find((basketItem) => {
      return basketItem.product.id == product.id;
    });
    if (productRef.quantity > 1) productRef.quantity--;
    this.afterChange();
  }

  incrementItem(product: Product) {
    const productRef = this.basket.find((basketItem) => {
      return basketItem.product.id == product.id;
    });
    productRef.quantity++;
    this.afterChange();
  }

  afterChange() {
    this.saveBasket();
    this.calculateTotal();
  }

  afterChangeCountryChange() {
    this.loadBasket().subscribe((basket) => {
      this.basket = basket;
      this.afterChange();
    });
  }

  calculateTotal() {
    const country = this.topNavService.countriesList.find(
      (c) => c.id == this.topNavService.country
    );

    const { totalInLocalCurrency, totalInLocalUSD } = this.basket.reduce(
      (total, basketItem) => {
        total.totalInLocalCurrency +=
          this.calculateProductPriceWithDiscount(
            basketItem.product,
            country,
            false
          ) * basketItem.quantity;
        total.totalInLocalUSD +=
          this.calculateProductPriceWithDiscount(
            basketItem.product,
            country,
            true
          ) * basketItem.quantity;

        return total;
      },
      {
        totalInLocalCurrency: 0,
        totalInLocalUSD: 0,
      }
    );

    this.totalInLocalCurrency = +totalInLocalCurrency.toFixed(2);
    this.totalInLocalUSD = +totalInLocalUSD.toFixed(2);
  }

  getProductPrice(
    product: Product,
    country: Country,
    priceInUDS: boolean = false
  ) {
    let price = 0;

    let priceForCountry = product.productsCountries.find((pc) => {
      return pc.country_id == country.id;
    });


    if (country?.code == 'JO') {
      price = product.jod_price;
    } else if (priceForCountry && priceInUDS) {
      price = priceForCountry.price;
    } else if (priceForCountry) {
      price = priceForCountry.price * country.exchange_rate;
    } else if (priceInUDS) {
      price = product.usd_price;
    } else {
      price = product.usd_price * country.exchange_rate;
    }
    return +price.toFixed(2);
  }

  calculateProductPriceWithDiscount(
    product: Product,
    country: Country,
    priceInUDS: boolean = false
  ) {
    let price = this.getProductPrice(product, country, priceInUDS);

    if (Number.isInteger(product.discount) && product.discount > 0) {
      price -= (price * product.discount) / 100;
    }
    return price;
  }

  async calculate(params: CalculatePriceParams): Promise<CalculatedPrice> {
    if (
      params.promotion &&
      !['STORE', 'SUBSCRIPTION'].includes(params.promotionType)
    ) {
      throw new Error('must set promotion type');
    }

    objectPath.ensureExists(params, 'discount', 0);
    objectPath.ensureExists(params, 'country', await this.getCountry());
    objectPath.ensureExists(params, 'promotion', null);
    objectPath.ensureExists(params, 'deliveryPrice', 0);

    if (typeof params.promotion == 'string') {
      params.promotion = await this.getPromotions(params.promotion).then(
        (r) => r.promotion
      );
    }

    let total = params.price;
    total -= (total * params.discount) / 100;
    total -= this.calculatePriceWithPromotion(
      total,
      params.promotion as Promotion,
      params.promotionType
    );

    const fixedTotal = +total.toFixed(2);

    return {
      prevuesPrice: params.price,
      currentPrice: total,
      fixedTotal,
      formattedCurrentPrice: `${fixedTotal} ${params.country.currency_ar}`,
      formattedPrevuesPrice: `${params.price} ${params.country.currency_ar}`,
      promotion: params.promotion as Promotion,
      promotionType: params.promotionType,
      discount: params.discount,
      priceInUSD: 0,
    };
  }

  private async getCountry() {
    const response = await this.countriesService.getCountries().toPromise();
    return response.data.find((c) => c.id == this.topNavService.country);
  }

  private getPromotions(code) {
    return this.promotionsService.getByCode(code).toPromise();
  }

  private calculatePriceWithPromotion(
    price: number,
    promotion: Promotion,
    type: 'STORE' | 'SUBSCRIPTION'
  ): number {
    let value = price;
    if (!promotion) return value;

    if (type === 'STORE' && promotion.store_unit === 'value') {
      value -= promotion.store_amount;
    }

    if (type === 'STORE' && promotion.store_unit === 'percent') {
      value = (price * promotion.store_amount) / 100;
    }

    if (type === 'SUBSCRIPTION' && promotion.subscription_unit === 'value') {
      value -= promotion.subscription_amount;
    }

    if (type === 'SUBSCRIPTION' && promotion.subscription_unit === 'percent') {
      value = (price * promotion.subscription_amount) / 100;
    }
    return value;
  }
}
