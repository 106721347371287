import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  private open(message: string, classes: string[]): void {
    this.snackBar.open(message, null, {
      duration: 1500,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: classes,
    });
  }

  warning(message: string): void {
    this.open(message, ['warning']);
  }

  success(message: string): void {
    this.open(message, ['success']);
  }

  error(message: string): void {
    this.open(message, ['error']);
  }
}
