import { Component, ElementRef, OnInit } from '@angular/core';
import { ProductsService } from '../../shop/services/product.service';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';

@Component({
  selector: 'app-get-products',
  templateUrl: './get-products.component.html',
  styleUrls: ['./get-products.component.scss'],
})
export class GetProductsComponent implements OnInit {
  loading: boolean = false;
  products: any = [];
  filteredProducts: any = [];
  homeProducts: any = [];

  constructor(
    private productService: ProductsService,
    public topNavService: TopNavService,
    private elementRef: ElementRef
  ) {}

  async ngOnInit() {
    this.products = await this.productService.getProducts().toPromise();

    this.filteredProducts = this.products.filter((product, idx, products) => {
      if (product.showInStore === true) {
        return product;
      }
    });
    // console.log(this.filteredProducts);
    //////////////////
    this.homeProducts = this.filteredProducts.filter(
      (product, idx, products) => {
        if (idx <= 2) {
          return product;
        }
      }
    );
    console.log(this.homeProducts);

    this.loading = true;
  }

  ngAfterViewInit() {
    //script

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/js/script.js';

    s.defer = true;
    s.async = true;
    this.elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };

    if ((typeof window as any['functionFromExternalScript']) === 'function') {
      window as any['functionFromExternalScript'];
      params;
    }
  }
}
