import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionTypeService extends HttpNestBase {
  constructor() {

    super(environment.API_URL, 'subscription-type');
    
  }
}
