import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TopNavService } from 'src/app/shared/components/nav-bar/top-nav.service';
import { ConversionService } from 'src/app/shared/services/api/conversion.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private topNavService: TopNavService,
    private titleService: Title,
    private conversionService: ConversionService,
    private activeRoute: ActivatedRoute,

    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.titleService.setTitle(environment.title + '- الرئيسية');
    this.topNavService.theme.next('light');
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let fbclid = this.activeRoute.snapshot?.queryParams?.fbclid;
      if (fbclid)
        localStorage.setItem(
          'fbclid',
          `fb.1.${moment(new Date()).unix()}.${fbclid}`
        );
      setTimeout(() => {
        this.conversionService.viewPage();
      }, 500);

      window.scrollTo(0, window.scrollY + 1);
      window.scrollTo(0, window.scrollY - 1);
    }

    // this.injectScript('/assets/js/script.js');
  }
}
