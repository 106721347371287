<!-- <div class="arrow-icon-container under-header">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->

<!-- <app-products-section></app-products-section> -->

<!-- <div class="arrow-icon-container under-poster-one">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->
<section class="header-section">
  <app-header-two></app-header-two>
  <app-products-section></app-products-section>
  <app-poster></app-poster>
  <app-poster-three></app-poster-three>
</section>
<!-- <app-poster-three></app-poster-three> -->

<!-- <div class="arrow-icon-container under-poster-two">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->

<!-- <app-header></app-header>
<div class="arrow-icon-container under-header">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->

<!-- <app-poster-two></app-poster-two>
<div class="arrow-icon-container under-poster-two">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->

<!-- <app-steps></app-steps> -->

<app-get-started></app-get-started>

<app-get-products></app-get-products>
<!-- <app-product></app-product> -->

<!-- <div class="arrow-icon-container under-poster-one">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->

<!-- <app-video-section></app-video-section> -->
<!-- <div class="arrow-icon-container under-poster-two">
  <div class="bottom-arrow">
    <div class="arrow"></div>
  </div>
</div> -->

<!-- <app-poster-three></app-poster-three> -->
<!-- <app-poster></app-poster> -->
<!-- <app-get-started></app-get-started> -->

<app-footer-two></app-footer-two>
