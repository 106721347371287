<section
  class="products-section"
  [ngClass]="{
    'products-section-2': router.url === aboutUrl.about,
    'products-section': router.url === homeUrl.home
  }"
>
  <div
    class="title-row"
    [ngClass]="{
      'title-row-2': router.url === aboutUrl.about,
      'title-row': router.url === homeUrl.home
    }"
  >
    <h3>بعض مشاريعنا</h3>
    <img src="../../../../../assets/lamp-img.png" alt="" />
    <!-- <img src="assets/header-two/NI-05.png" alt="" /> -->
  </div>

  <!-- //////////////////////////////////////////////////////////////// -->

  <div class="container some-class" *ngIf="router.url === homeUrl.home">
    <div class="swiper tranding-slider">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <ng-container *ngFor="let slide of slides">
          <!-- Slide 1 -->

          <div class="swiper-slide">
            <video
              style="width: 100%; height: 100%; object-fit: cover"
              data-lazyload="true"
              secure="true"
              loop="true"
              autoplay
              muted="true"
              playsinline="true"
              [src]="slide.image"
              id="video"
              #vid
            ></video>
          </div>
        </ng-container>
      </div>

      <!-- If we need navigation buttons -->
      <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->
    </div>
  </div>

  <div class="container some-class" *ngIf="router.url === aboutUrl.about">
    <div class="swiper tranding-slider">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <ng-container *ngFor="let slide of slider">
          <!-- Slide 1 -->
          <div class="swiper-slide">
            <video
              style="width: 100%; height: 100%; object-fit: cover"
              data-lazyload="true"
              secure="true"
              loop="true"
              autoplay
              muted="true"
              playsinline="true"
              [src]="slide.image"
              id="video"
              #vid
            ></video>
          </div>
        </ng-container>
      </div>

      <!-- If we need navigation buttons -->
      <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->
    </div>
  </div>
</section>
