import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class NavBarService {
  menuSwitch = new Subject<boolean>();
}
