import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { merge, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TopNavService } from '../shared/components/nav-bar/top-nav.service';
import { AuthService } from '../shared/services/api/auth.service';
import { NavBarService } from '../shared/services/nav-bar/nav-bar.service';
import { VariablesService } from '../shared/services/api/variables.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  @ViewChild(MatDrawer) menu: MatDrawer;
  title = 'Barq Box';
  subscriptions: Array<Subscription> = [];
  constructor(
    public topNavService: TopNavService,
    private authService: AuthService,
    public navBarService: NavBarService,
    public variablesService: VariablesService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }
  showToolBar: any;
  ngOnInit(): void {
   
    if (isPlatformBrowser(this.platformId)) {
      document.body.setAttribute('dir', 'rtl');
    }
    this.authService.patchAuthStateThereAreAToken();
    const sub = merge(
      this.topNavService.menuSwitch,
      this.navBarService.menuSwitch,
      this.router.events.pipe(
        filter((e) => e instanceof NavigationStart),
        map(() => false)
      )
    ).subscribe((value: boolean) => {
      if (isPlatformBrowser(this.platformId)) {     
        value ? this.menu?.open() : this.menu?.close();
        window.scroll({ top: 0, left: 0 });
      }
    });
    this.subscriptions.push(sub);
    this.variablesService.getToolBarMsg().subscribe(response => {
      if(response.length) {
        this.showToolBar = response[0];
      }
    });
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
