// // import the interface
import { Country } from '../models/country.model';
import { CountryAction, CountryActionType } from '../actions/country.action';
import { Platform } from '@angular/cdk/platform';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// //create a dummy initial state
const initialState: any = typeof window !== 'undefined' ? localStorage.getItem('country') ? JSON.parse(localStorage.getItem('country')) : { id: 1, name_ar: 'الاردن', cod: true, code: 'JO' } : { id: 1, name_ar: 'الاردن', cod: true, code: 'JO' };

export function CountryReducer(
  state: Country = initialState,
  action: CountryAction
) {


  switch (action.type) {
    case CountryActionType.ADD_ITEM: {
      return action.payload;
    }
    default:
      return state;
  }
}
