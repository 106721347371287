import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageRange'
})
export class AgeRangePipe implements PipeTransform {

  transform(value: { minAge?: number, maxAge?: number }): string {
    if (value) {
      const { minAge, maxAge } = value;
      if (minAge && maxAge) { return `من ${minAge} الى ${maxAge} ${maxAge > 10 ? 'سنة' : 'سنوات'}`; }
      else if (minAge && !maxAge) { return `اكثر من ${minAge} ${minAge > 10 ? 'سنة' : 'سنوات'}`; }
      else if (!minAge && maxAge) { return `اقل من ${maxAge} ${maxAge > 10 ? 'سنة' : 'سنوات'}`; }
      else { return ''; }
    }
  }
}
