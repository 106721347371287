import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponse, Subscription } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService extends HttpNestBase {
  sendTypeId = new BehaviorSubject<object>({});

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    super(environment.API_URL, 'subscription');
  }

  setTypeId(data: {}) {
    return this.sendTypeId.next(data);
  }

  getTypeId(): Observable<object> {
    return this.sendTypeId.asObservable();
  }

  public types: any;

  getActiveSubscriptions(
    country_id = 1,
    type,
    months = null
  ): Observable<APIResponse<Subscription>> {
    const filters = this.find()
      .setFilter({ field: 'active', operator: '$eq', value: true })
      .setFilter({ field: 'country_id', operator: '$eq', value: country_id })
      .setFilter({
        field: 'subscription_type_id',
        operator: '$eq',
        value: type,
      })

      .sortBy({ field: 'months', order: 'ASC' })
      .setJoin([
        { field: 'subscription_type' },
        { field: 'country' },
        { field: 'subscription_type.image' },
      ])
      .setLimit(1000);

    if (months != null)
      filters.setFilter({ field: 'months', operator: '$eq', value: months });

    return filters.execute<APIResponse<Subscription>>();
  }

  patchSubscriptionActivityState(
    id: number,
    activityState: boolean
  ): Observable<Subscription> {
    return this.patch(id).setBody({ active: activityState }).execute();
  }
}
