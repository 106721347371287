<div dir="rtl">
  <mat-drawer-container autosize>
    <mat-drawer #drawer mode="over">
      <app-side-nav-bar></app-side-nav-bar>
    </mat-drawer>
    <mat-drawer-content>
      <app-nav-bar></app-nav-bar>
      <div [ngClass]="showToolBar?.visibility? 'main-app-container-top-padding2' : 'main-app-container-top-padding'"> 
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
