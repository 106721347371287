import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APIResponse, Country } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends HttpNestBase {
  constructor(private http: HttpClient) {
    super(environment.API_URL, 'countries');
  }

  getCountries(): Observable<APIResponse<Country>> {
    return this.find()
      .select([
        'id',
        'name_ar',
        'code',
        'currency',
        'cod',
        'currency_ar',
        'exchange_rate',
      ])
      .setLimit(10000)
      .execute();
  }
  getCountriesWithDefault(): Observable<Array<Country>> {
    return this.setSubRoute('default').get().execute();
  }

  getCitiesByCountryCode(
    city: any = '',
    countryCode: any = '',
    zip: any = ''
  ): Observable<any> {
    const url = `https://ws.aramex.net/AramexWebApi/WebServices/Geo/GetCities?city=${city}&countryCode=${countryCode}&zip=${zip}`;
    return this.http.get(url).pipe(map((d) => d));
  }

  getSelectedCountry() {
    if (!!localStorage.getItem('country_id'))
      return this.get(localStorage.getItem('country_id'))
        .select([
          'id',
          'name_ar',
          'code',
          'currency',
          'cod',
          'currency_ar',
          'exchange_rate',
        ])
        .execute();
    else return of(null);
  }
}
