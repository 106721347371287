import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppState } from 'src/app/store/models/app-state.model';
import { AuthService } from '../../services/api/auth.service';
import { CountriesService } from '../../services/api/countries.service';
import { NAV_THEME, TopNavService } from './top-nav.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { SetCountryAction } from 'src/app/store/actions/country.action';
import { SnakbarComponent } from 'src/app/user/pages/shop/components/snakbar/snakbar.component';
import { BasketService } from 'src/app/user/pages/shop/services/basket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserSubscriptionsService } from '../../services/api/user-subscriptions.service';
import { switchMap } from 'rxjs/operators';
import { VariablesService } from '../../services/api/variables.service';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  theme: NAV_THEME = 'light';
  country: any;
  countries: any = [];
  courseItems$: Observable<any>;
  constructor(
    public topNavService: TopNavService,
    public variablesService: VariablesService,
    public authService: AuthService,
    public basketService: BasketService,
    private countriesService: CountriesService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userSubscriptionService: UserSubscriptionsService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store<AppState>
  ) {}
  showToolBar: any;
  async ngOnInit() {
    this.authService.user$
      .pipe(
        switchMap((user) => {
          if (user)
            return this.userSubscriptionService.getLastUncompletedSubscription(
              user?.id
            );
          else return of(null);
        })
      )
      .subscribe((r) => {
        if (r?.subscription_group?.status == null)
          this.topNavService.subscriptionGroup = r?.subscription_group?.id;
      });

    this.courseItems$ = this.store.select((store) => store.Country);

    if (isPlatformBrowser(this.platformId)) {
      this.countries = await this.countriesService
        .getCountriesWithDefault()
        .toPromise();
      if (!localStorage.getItem('country'))
        localStorage.setItem(
          'country',
          JSON.stringify(this.countries.filter((d) => d.default)[0])
        );

      const country = JSON.parse(localStorage.getItem('country'));
      this.countries.forEach((element) => {
        if (element.id == country.id) {
          localStorage.setItem('country', JSON.stringify(element));
          this.country = element;
        }
      });
      this.store.dispatch(new SetCountryAction(this.country));
      this.currencySwitch(this.country, true);
      this.topNavService.theme.subscribe((theme) => (this.theme = theme));
    }
    this.variablesService
      .getToolBarMsg()
      .subscribe((response) => (this.showToolBar = response[0]));
  }

  menuSwitch(): void {
    this.topNavService.menuSwitch.next(true);
  }

  currencySwitch(value, despatch = true) {
    this.countries.forEach((element) => {
      if (element.id == value.id) {
        localStorage.setItem('country', JSON.stringify(element));
        localStorage.setItem('country_id', element.id);
        this.country = element;
        this.topNavService.triggerSomeEvent(element.id, null);
        //to change currency of product WHEN COUNTRY CHANGE
        this.topNavService.country = element.id;
        this.topNavService.country_exchange_rate = element.exchange_rate;
        //TO UPDATE THE TOTAL WHEN COUNTRY CHANGE
        console.log('this.basketService.afterChangeCountryChange()');
        this.basketService.afterChangeCountryChange();
      }
    });
    if (isPlatformBrowser(this.platformId))
      localStorage.setItem('country', JSON.stringify(this.country));
    if (despatch) this.store.dispatch(new SetCountryAction(this.country));
  }

  openBasket() {
    if (this.basketService.basket.length == 0) {
      this._snackBar.openFromComponent(SnakbarComponent, {
        verticalPosition: 'bottom',
        horizontalPosition: 'start',
        panelClass: ['snackBarStyle'],
        data: { title: 'لا يوجد عناصر في السلة' },
        duration: 3 * 1000,
      });
      setTimeout(() => {
        this.router.navigate(['shop']);
      }, 500);
    } else {
      this.router.navigate(['shop/basket']);
    }
  }
}
