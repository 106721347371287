import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-snakbar',
  templateUrl: './snakbar.component.html',
  styleUrls: ['./snakbar.component.scss']
})
export class SnakbarComponent implements OnInit {
  ;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
   @Inject(MatSnackBarRef) public snackBarRef,
  private router: Router
  ) { }

  openBasket() {
    this.router.navigate(['shpp/basket']);
  }
  ngOnInit(): void {
  }

}
