<section *ngIf="subscriptions?.length > 0">
  <!-- <span class="subscriptions">
    يجب ان تكون جميع الاشتراكات بنفس المدة بالطلب الواحد</span
  > -->
</section>

<section class="section-type-input s-32">
  <h3
    class="form-section-title"
    [ngClass]="{
      'form-section-title-2': router.url === homeUrl.home
    }"
  >
    اختر نوع الاشتراك :
  </h3>
  <div [class]="'proper-age-container ' + theme">
    <ng-container *ngIf="list$ | async as list">
      <div
        [id]="'card-age-card' + sub.id"
        class="list"
        *ngFor="let sub of list"
        matRipple
        [class.selected]="id == sub.id"
        (click)="selected(sub)"
        [ngClass]="{
          'subscription-after-1': sub.months == 12,
          'subscription-after': sub.months == 3
        }"
      >
        <!-- step-1 -->
        <span class="title" [id]="'card-age-title' + sub.id">
          كل {{ sub.months > 1 ? sub.months : "" }}

          {{ sub.months == 1 || sub.months >= 12 ? "شهر" : "اشهر" }}
        </span>

        <div [ngClass]="sub.months > 1 ? 'list-step-box' : 'list-step-box-1'">
          <!-- step-2 -->

          <span class="card-price" [id]="'card-age-price' + sub.id">
            <!-- <span *ngIf="sub.old_price"
              >{{ sub.old_price }} {{ sub.country.currency_ar }}</span
            > -->
            <b class="sub-price">
              {{ sub.price_convert ? sub.price_convert : sub.price }}
              {{ sub.country.currency_ar }}
            </b>
          </span>
          <!-- <mat-divider [vertical]="true"></mat-divider> -->

          <!-- step-3 -->
          <div *ngIf="sub.months > 1">
            <span [ngClass]="{ sideper: sub.months != 1 }" class="card-price">
              <span class="below">يعادل</span
              >{{
                (sub.price_convert ? sub.price_convert : sub.price) / sub.months
                  | number : "1.2-2"
              }}
              {{ sub.country.currency_ar }} / شهر
            </span>
            <!-- <span *ngIf="calclatePer(list[0], sub) != 0" class="per"
            >{{ calclatePer(list[0], sub) }}%-</span
          > -->
            <span class="free">توصيل مجاني</span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!list$">
      <div class="loading">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </div>
</section>

<div class="s-33">
  <div class="swiper-sub">
    <div class="swiper-wrapper">
      <ng-container *ngIf="list$ | async as list">
        <div *ngFor="let sub of list" class="swiper-slide swiper-slide--one">
          <section class="section-type-input-2">
            <div [class]="'proper-age-container ' + theme">
              <div
                [id]="'card-age-card' + sub.id"
                class="list"
                matRipple
                [class.selected]="id == sub.id"
                (click)="selected(sub)"
                [ngClass]="{
                  'subscription-after-1': sub.months == 12,
                  'subscription-after': sub.months == 3
                }"
              >
                <span class="title" [id]="'card-age-title' + sub.id">
                  كل {{ sub.months > 1 ? sub.months : "" }}

                  {{ sub.months == 1 || sub.months >= 12 ? "شهر" : "اشهر" }}
                </span>

                <div
                  [ngClass]="
                    sub.months > 1 ? 'list-step-box' : 'list-step-box-1'
                  "
                >
                  <span class="card-price" [id]="'card-age-price' + sub.id">
                    <b class="sub-price">
                      {{ sub.price_convert ? sub.price_convert : sub.price }}
                      {{ sub.country.currency_ar }}
                    </b>
                  </span>

                  <div *ngIf="sub.months > 1">
                    <span
                      [ngClass]="{ sideper: sub.months != 1 }"
                      class="card-price"
                    >
                      <span class="below">يعادل</span
                      >{{
                        (sub.price_convert ? sub.price_convert : sub.price) /
                          sub.months | number : "1.2-2"
                      }}
                      {{ sub.country.currency_ar }} / شهر
                    </span>

                    <span class="free">توصيل مجاني</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </ng-container>

      <ng-container *ngIf="!list$">
        <div class="loading">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination-2"></div>
  </div>
</div>
