import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';

@Component({
  selector: 'app-poster',
  templateUrl: './poster.component.html',
  styleUrls: ['./poster.component.scss'],
})
export class PosterComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  async ngOnInit(): Promise<void> {
    // this.injectScript('/assets/js/script-2.js');
  }

  // public injectScript(src: string) {
  //   if (this.document && src?.trim()) {
  //     const script = this.document.createElement('script');
  //     script.setAttribute('type', 'text/javascript');
  //     script.setAttribute('src', src.trim());
  //     this.document.head?.appendChild(script);
  //   }
  // }

  ngAfterViewInit() {
    //script

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/js/script-2.js';

    s.defer = true;

    this.elementRef.nativeElement.appendChild(s);

    var s2 = document.createElement('script');
    s2.type = 'text/javascript';
    s2.src = '/assets/js/script-3.js';

    s2.defer = true;

    this.elementRef.nativeElement.appendChild(s2);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };

    if ((typeof window as any['functionFromExternalScript']) === 'function') {
      window as any['functionFromExternalScript'];
      params;
    }
  }

  // ngAfterViewInit() {
  //   var s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '/assets/js/script.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  // afterScriptAdded() {
  //   const params = {
  //     width: '350px',
  //     height: '420px',
  //   };

  //   if ((typeof window as any['functionFromExternalScript']) === 'function') {
  //     window as any['functionFromExternalScript'];
  //     params;
  //   }
  // }

  // ngAfterViewInit() {
  //   var s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '/assets/js/script.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  // afterScriptAdded() {
  //   const params = {
  //     width: '350px',
  //     height: '420px',
  //   };

  //   if ((typeof window as any['functionFromExternalScript']) === 'function') {
  //     window as any['functionFromExternalScript'];
  //     params;
  //   }
  // }
}
