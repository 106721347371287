import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, of } from 'rxjs';
import { delay, first, switchMap, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  userAgent: {
    full: string; //'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36',
    name: string; // 'chrome',
    version: string; // '96.0.4664.45',
    fullName: string; // 'chrome 96.0.4664.45',
    os: string; // 'Linux',
    device_type: string; // 'desktop'

  };
  private _footerAndHiderHeight$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId))
      fromEvent(window, 'resize').subscribe(() => this.calculateFooterAndHiderHeight());
  }
  get footerAndHiderHeight$() {
    return of(null)
      .pipe(
        delay(0),
        first(),
        tap(() => this.calculateFooterAndHiderHeight()),
        switchMap(() => this._footerAndHiderHeight$.asObservable())
      )
  }

  setDeviceInfo(info) {
    this.userAgent = info;
  }
  private calculateFooterAndHiderHeight() {
    let height = 0;
    if (isPlatformBrowser(this.platformId)) {
      const FH = document.getElementById('footer-height')?.offsetHeight;
      const HH = document.getElementById('heder-height')?.offsetHeight;
      if (FH) height += FH;
      if (HH) height += HH;
    }
    this._footerAndHiderHeight$.next(height);
  }
}
