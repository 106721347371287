import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './shared/services/api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IfNotLoginGuard implements CanActivate {
  constructor(private auhService: AuthService){}
  canActivate(): boolean {
    return this.auhService.isTokenValid();
  }
}
