import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

interface Image {
  maxWidth: number;
  height: number;
  width: number;
  src: string;
  alt: string;
}
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  _images: Image[] = [];
  @Input() alt: string = '';
  @Input() lazy: boolean = true;
  @Input() width: number;
  @Input() height: number;
  @Input() set images(images: Image[]) {
    // of(images).pipe(delay(3000)).subscribe(i => this._images = i);
    this._images = images;
  };


  constructor() { }

  ngOnInit(): void {
  }

}
