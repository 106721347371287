import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordBody, User } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends HttpNestBase {

  constructor() {
    super(environment.API_URL, 'users');

  }


  isEmailUnique(email: string): Observable<boolean> {
    return this.setSubRoute(['is-email-unique', email]).get()
      .execute();
  }

  updateUserInfo(id: number, body: User): Observable<User> {
    return this
      .update(id)
      .setBody(body)
      .execute<User>();
  }

  updateUserPassword(id: number, body: ChangePasswordBody): Observable<any> {
    return this
      .update('change-password/' + id)
      .setBody(body)
      .execute<any>();
  }
}

