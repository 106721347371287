import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProduct',
})
export class FilterProductPipe implements PipeTransform {
  transform(product: any): any {
    let productStore: any[] = [];
    for (let products of product) {
      if (products.showInStore == true) {
        productStore.push(products);
      }
    }
    return productStore;
  }
}
