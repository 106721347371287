import { Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { AuthService } from '../../services/api/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/models/app-state.model';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { CountriesService } from '../../services/api/countries.service';
import { SetCountryAction } from 'src/app/store/actions/country.action';
import { NAV_THEME, TopNavService } from '../nav-bar/top-nav.service';
import { BasketService } from 'src/app/user/pages/shop/services/basket.service';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
})
export class SideNavBarComponent implements OnInit{
  theme: NAV_THEME = 'light';
  courseItems$: Observable<any>;
  country: any;
  countries: any = [];

  constructor(
    public authService: AuthService,
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId: object,
    private countriesService: CountriesService,
    private topNavService: TopNavService,
    private basketService: BasketService,
  ) {}

  async ngOnInit() {
    this.courseItems$ = this.store.select((store) => store.Country);

    if (isPlatformBrowser(this.platformId)) {
      this.countries = await this.countriesService
        .getCountriesWithDefault()
        .toPromise();
      if (!localStorage.getItem('country'))
        localStorage.setItem(
          'country',
          JSON.stringify(this.countries.filter((d) => d.default)[0])
        );

      const country = JSON.parse(localStorage.getItem('country'));

      this.countries.forEach((element) => {
        if (element.id == country.id) {
          localStorage.setItem('country', JSON.stringify(element));
          this.country = element;
        }
      });
      this.store.dispatch(new SetCountryAction(this.country));

      this.topNavService.theme.subscribe((theme) => (this.theme = theme));
    }
  }

  currencySwitch(value, despatch = true) {
    this.countries.forEach((element) => {
      if (element.id == value.id) {
        localStorage.setItem('country', JSON.stringify(element));
        this.country = element;
        this.topNavService.triggerSomeEvent(element.id, null);
        //to change currency of product WHEN COUNTRY CHANGE
        this.topNavService.country = element.id;
        this.topNavService.country_exchange_rate = element.exchange_rate;

        //TO UPDATE THE TOTAL WHEN COUNTRY CHANGE
        this.basketService.calculateTotal();
      }
    });
    if (isPlatformBrowser(this.platformId))
      localStorage.setItem('country', JSON.stringify(this.country));
    if (despatch) this.store.dispatch(new SetCountryAction(this.country));
  }
}
