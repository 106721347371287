import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { delay, first, map } from 'rxjs/operators';
import { Subscription } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { SubscriptionsService } from '../../services/api/subscriptions.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/models/app-state.model';
import { SubscriptionTypeService } from '../../services/api/subscription-type.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-subscription-type-input',
  templateUrl: './subscription-type-input.component.html',
  styleUrls: ['./subscription-type-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubscriptionTypeInputComponent),
      multi: true,
    },
  ],
})
export class SubscriptionTypeInputComponent
  implements ControlValueAccessor, OnInit
{
  environment = environment;
  list$: Observable<Array<Subscription>>;
  @Input() theme = '';
  @Output() changed = new EventEmitter<Subscription>();

  _value: Subscription = null;

  onChange: any;
  onTouched: any;
  isTouched = false;

  constructor(
    public router: Router,
    private subscriptionsService: SubscriptionsService,
    private subscriptionsTypeService: SubscriptionTypeService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId))
      this.list$ = this.subscriptionsTypeService
        .find()
        .setJoin({ field: 'image' })
        .execute()
        .pipe(
          delay(300),
          first(),
          map((res) => res.data)
        );
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    this._value = v;
  }

  get id(): number {
    return this.value?.id;
  }

  selected(value): void {
    this.value = value;
    this.changed.emit(value);
    this.update();
  }

  touch(): void {
    this.isTouched = true;
  }

  writeValue(value: Subscription): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    // this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {}

  update(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  public homeUrl = {
    home: '/home',
  };
}
