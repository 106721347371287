import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss'],
})
export class HeaderTwoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  // isDesktops() {
  //   const mediaQueryList = window.matchMedia(`(min-width: 1200px)`);

  //   console.log(mediaQueryList.matches); // true or false

  //   mediaQueryList.addEventListener(
  //     'change',
  //     (event) => console.log(event.matches) // true or false
  //   );

  //   return mediaQueryList.matches;
  //   // don't forget to remove the event listener ;)
  // }
}
