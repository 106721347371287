<div class="con">
  <section class="contact-us">
    <div class="header">
      <h3>تواصل معنا</h3>
      <span class="hr"></span>
    </div>

    <div class="contact-us-body">
      <a
      *ngIf="country.id == 1"
      style="justify-content: flex-end"
      dir="ltr"
      class="pt-1"
      href="tel:+96265379292"
      >+962 6 537 92 92<mat-icon>call</mat-icon></a>
      <a
      style="justify-content: flex-end"
      dir="ltr"
      class="pt-1"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=962799186661"
      >WhatsApp<mat-icon>chat</mat-icon></a>
      <a href="mailto:contactus@barqboxes.com"
        ><mat-icon>email</mat-icon>contactus@barqboxes.com</a
      >
      <a href="mailto:support@barqboxes.com"
        ><mat-icon>email</mat-icon>support@barqboxes.com</a
      >


      
    </div>

    <a class="contact-us-footer" href="https://codeobia.com/">
      <span>طور بواسطة</span>

      <!-- <img src="assets/codeobia.png" alt="" /> -->
      <app-image
        [images]="[
          {
            alt: 'codeobia',
            src: 'assets/codeobia.png'
          }
        ]"
      ></app-image>
    </a>
  </section>

  <section class="site-map">
    <div class="header">
      <h3>خريطة الموقع</h3>
      <span class="hr"></span>
    </div>
    <div class="site-map-body">
      <a mat-button [routerLink]="'/terms-of-use'"> شروط الاستعمال</a>
      <a mat-button [routerLink]="'/privacy-policy'">سياسة الخصوصية</a>
      <!-- <a mat-button [routerLink]="'/promotions-offer'"> التسويق بالعمولة </a> -->
      <a mat-button [routerLink]="'/about-us'">من نحن</a>
    </div>
    <div class="site-map-footer">
      حقوق النشر محفوظة<mat-icon>copyright</mat-icon>
    </div>
  </section>
  <section class="developed-by">
    <div class="header">
      <h3>تابعنا</h3>
      <span class="hr"></span>
    </div>
    <div class="developed-by-body">
      <!-- <img src="assets/Image-14.svg" alt="" /> -->
      <app-image
        [images]="[
          {
            alt: '',
            src: 'assets/Image-14.svg'
          }
        ]"
      ></app-image>

      <div class="icons-container">
        <div class="icon-row">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.facebook.com/barqboxes"
          >
            <!-- <img src="assets/Image-15.svg" alt="" /> -->
            <app-image
              [images]="[
                {
                  alt: '',
                  src: 'assets/Image-15.svg'
                }
              ]"
            ></app-image>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://www.instagram.com/barqboxes/"
          >
            <!-- <img src="assets/Image-16.svg" alt="" /> -->
            <app-image
              [images]="[
                {
                  alt: '',
                  src: 'assets/Image-16.svg'
                }
              ]"
            ></app-image>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/barq_boxes"
          >
            <!-- <img src="assets/Image-17.svg" alt="" /> -->
            <app-image
              [images]="[
                {
                  alt: '',
                  src: 'assets/Image-17.svg'
                }
              ]"
            ></app-image>
          </a>
        </div>
        <div class="icon-row">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.pinterest.com/barqboxes"
          >
            <!-- <img src="assets/Image-18.svg" alt="" /> -->
            <app-image
              [images]="[
                {
                  alt: '',
                  src: 'assets/Image-18.svg'
                }
              ]"
            ></app-image>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://www.youtube.com/channel/UCqJUf1I5s0-ST4nAfIo1KAQ"
          >
            <!-- <img src="assets/Image-19.svg" alt="" /> -->
            <app-image
              [images]="[
                {
                  alt: '',
                  src: 'assets/Image-19.svg'
                }
              ]"
            ></app-image>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://www.tiktok.com/@barq.boxes?"
          >
            <!-- <img src="assets/Image-20.svg" alt="" /> -->
            <app-image
              [images]="[
                {
                  alt: '',
                  src: 'assets/Image-20.svg'
                }
              ]"
            ></app-image>
          </a>
        </div>
      </div>
    </div>
    <div class="developed-by-footer">
      <div class="payments-method-icons">
        <!-- <img src="assets/footer/paypal.svg" alt="paypal" /> -->
        <!-- <img src="assets/footer/visa.svg" alt="visa" />
        <img src="assets/footer/mastercard.svg" alt="mastercard" /> -->
        <!-- <img src="assets/footer/paypal.svg" alt="paypal" /> -->
        <!-- <img src="assets/footer/visa.svg" alt="visa" /> -->
        <app-image
          [images]="[
            {
              alt: 'mastercard',
              src: 'assets/footer/mastercard.svg'
            }
          ]"
        ></app-image>
        <!-- <img src="assets/footer/mastercard.svg" alt="mastercard" /> -->
        <app-image
          [images]="[
            {
              alt: 'visa',
              src: 'assets/footer/visa.svg'
            }
          ]"
        ></app-image>
      </div>
    </div>
  </section>
</div>
